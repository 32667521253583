<script setup lang="ts">
import type { Colors, Tag, VueClass } from '@/api/types';
import { translateStringOrLocale } from '@/i18n';
import { computed, useAttrs } from 'vue';

const props = defineProps<{
  tags?: Pick<Tag, 'text' | 'color'>[];
  size?: 'badge-xs' | 'badge-sm' | 'badge-md' | 'badge-lg';
  color?: Colors;
  containerClass?: VueClass;
}>();

const translatedTags = computed(() =>
  props.tags?.map((t) => ({
    text: translateStringOrLocale(t.text).value,
    color: props.color ?? t.color ?? 'green-cyan',
  })),
);

defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();
</script>

<template>
  <div
    v-for="(tag, idx) in translatedTags"
    :key="idx"
    class="tooltip max-w-full"
    :class="containerClass"
    :data-tip="tag.text"
  >
    <p
      class="badge badge-outline block max-w-full truncate border-gnist-gray"
      :class="[size ?? 'badge-sm', `bg-gnist-${tag.color}`, attrs.class]"
    >
      #{{ tag.text }}
    </p>
  </div>
</template>

<style scoped>
.tooltip:before {
  width: auto;
}
</style>
