<script setup lang="ts">
import MaterialIcon from './MaterialIcon.vue';
import CurrentUser from './CurrentUser.vue';
import { useCurrentUser, signIn, getSignoutUrl } from '@/api/auth';
import DropdownMenu from './DropdownMenu.vue';
import { computed, ref } from 'vue';
import ButtonComponent from './ButtonComponent.vue';
import { SUPPORTED_LOCALES, env_type, type supported_locale } from '@/config';
import type { RouteItem, RouteItemWithChildren } from '@/api/types';
import { throwOnError } from '@/api/composable';
import { useMenu } from '@/api/menu';
import SearchBar from '../components/SearchBar.vue';
import NotificationToast from './NotificationToast.vue';
import { useRouter } from 'vue-router';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStoreAbility } from '@/abilities';
import { set_locale } from '@/i18n';
import { useResizeListener } from '@/utilities/useResizeListener';

const { t, locale } = useI18n();
const { can } = useStoreAbility();

console.timeLog('Startup', 'loading header');

const userInfo = useCurrentUser();

const { result: menuItems } = throwOnError(useMenu());

const userMenuItems: (RouteItem & { showNotificationToast?: boolean })[] = [
  {
    text: 'header.items.mypage',
    targetPath: '/profile',
    routeType: 'hardcoded',
  },
  {
    text: 'header.items.notifications',
    targetPath: '/notifications',
    routeType: 'hardcoded',
    showNotificationToast: true,
  },
  {
    text: can('update', 'Organization')
      ? 'header.items.organizationsAdmin'
      : userInfo.value?.roles?.includes('orgmember')
        ? 'header.items.organizations'
        : 'header.items.organizationsCreate',
    targetPath: '/organizations',
    routeType: 'hardcoded',
  },
  {
    text: 'header.logout',
    href: getSignoutUrl(),
    targetPath: '/',
    routeType: 'hardcoded',
  },
];

const hardcodedMenuItems: RouteItem[] = [
  {
    text: 'header.items.producerTools',
    targetPath: '/',
    routeType: 'header',
    action: 'update',
    subject: 'Block',
    items: [
      {
        text: 'header.items.admin',
        targetPath: '/admin',
        action: 'update',
        subject: 'Block',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.moderator',
        targetPath: '/social/moderator',
        action: 'delete',
        subject: 'Comment',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.editorOverview',
        targetPath: '/admin/editor_management',
        action: 'manage',
        subject: 'Block',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.myDocuments',
        targetPath: '/admin/my_documents',
        action: 'own',
        subject: 'Block',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.analytics',
        targetPath: '/analytics',
        action: 'read',
        subject: 'Analytics',
        routeType: 'hardcoded',
      },
    ],
  },
  {
    text: 'header.items.adminTools',
    targetPath: '/',
    routeType: 'header',
    action: 'update',
    subject: 'User',
    items: [
      {
        text: 'header.items.userAdmin',
        targetPath: '/users',
        action: 'update',
        subject: 'User',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.routeAdmin',
        targetPath: '/routes',
        action: 'update',
        subject: 'Route',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.categoryAdmin',
        targetPath: '/categories',
        action: 'update',
        subject: 'Category',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.tagAdmin',
        targetPath: '/tags/edit',
        action: 'update',
        subject: 'Tags',
        routeType: 'hardcoded',
      },
      {
        text: 'header.items.tagUsageAdmin',
        targetPath: '/tags/usages',
        action: 'update',
        subject: 'Tags',
        routeType: 'hardcoded',
      },
    ],
  },
];

const localeMenuItems = computed((): RouteItem[] => [
  {
    text: 'language.selector',
    targetPath: '/',
    routeType: 'header',
    mobileOnly: true,
    items: SUPPORTED_LOCALES.map(
      (l): RouteItem => ({
        text: 'language.values.' + l,
        onClick: () => set_locale(l, route),
        targetPath: '/',
        routeType: 'hardcoded',
        isSelected: locale.value === l,
      }),
    ),
  },
]);

const { isMedium } = useResizeListener();
const filterTree = (items: RouteItem[]) => {
  return items
    .filter((item) => (isMedium.value ? !item.mobileOnly : true))
    .map(
      (i): RouteItem => ({
        ...i,
        items:
          (i as RouteItemWithChildren).items === undefined
            ? undefined
            : filterTree((i as RouteItemWithChildren).items),
      }),
    );
};
const filteredMenuItems = computed(() => {
  return filterTree([
    ...(menuItems.value ?? []),
    ...hardcodedMenuItems,
    ...localeMenuItems.value,
  ]);
});
const router = useRouter();
const search = (input: string) => {
  router.push(getBlocksRouteLink(undefined, { q: input }));
};

const route = useRoute();
const showMobileSearch = ref(false);

const searchFocused = ref(false);
</script>
<template>
  <div class="HeaderContainer" :class="{ isMedium }">
    <header
      class="z-menu box-border whitespace-nowrap font-semibold text-gnist-sp-blue-dark"
      :class="[
        showMobileSearch ? 'h-40' : 'h-16 lg:h-24',
        {
          'bg-gnist-blue-light-light': env_type === 'prod',
          'bg-gnist-green-cyan': env_type === 'test',
          'bg-gnist-yellow': env_type === 'dev',
        },
      ]"
    >
      <div class="h-16 p-2 sm:px-4 md:pl-6 lg:h-24 lg:py-8 lg:pl-12 lg:pr-8">
        <nav
          class="NavContainer xs:gap-4 static grid grid-cols-[max-content_auto_max-content_max-content_max-content] items-center gap-1"
        >
          <RouterLink to="/" class="flex">
            <img
              alt="Gnist logo"
              class="xs:h-8 inline-block h-7 md:h-9 lg:max-h-10"
              src="../assets/gnist_logo.svg"
            />
          </RouterLink>
          <div class="SearchContainer flex min-w-0 flex-row justify-end">
            <SearchBar
              v-if="!route.meta.hideSearchBar && isMedium"
              class="text-p2 transition-all duration-1000"
              :class="{
                'w-11/12 max-w-md': isMedium,
                'w-36': isMedium && !searchFocused,
                'w-full': isMedium && searchFocused,
                '!pr-1': !searchFocused,
              }"
              @submit="search"
              @focus="searchFocused = true"
              @blur="searchFocused = false"
            />
            <button
              v-if="!route.meta.hideSearchBar && !isMedium"
              class="btn btn-circle glass btn-info bg-gnist-white"
              @click="() => (showMobileSearch = !showMobileSearch)"
            >
              <MaterialIcon class="px-5 text-2xl"> search </MaterialIcon>
            </button>
          </div>
          <DropdownMenu
            v-if="false"
            :items="localeMenuItems"
            class="relative hidden md:block md:[&>button]:px-2"
            :menu-items-class="[
              'px-2 py-2',
              'min-w-[10rem]',
              'absolute top-16 right-0',
              'flex-col whitespace-nowrap',
              'bg-gnist-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5',
              'lg:[&>*]:basis-20',
            ]"
            data-cy-id="LanguageMenu"
          >
            <template #button>
              <div
                class="flex cursor-pointer items-start"
                data-cy-id="LanguageSelector"
              >
                <MaterialIcon class="text-3xl"> language </MaterialIcon>
                <span class="ml-1 text-xl">
                  {{ locale }}
                </span>
              </div>
              <span class="sr-only">{{ t('header.languageMenu') }}</span>
            </template>
          </DropdownMenu>
          <div class="UserMenuContainer">
            <DropdownMenu
              v-if="userInfo"
              :items="userMenuItems"
              class="relative"
              :menu-items-class="[
                'px-2 py-2',
                'w-64',
                'absolute top-16 right-0',
                'flex-col whitespace-nowrap',
                'bg-gnist-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5',
              ]"
              data-cy-id="UserMenu"
            >
              <template #button>
                <CurrentUser class="mr-2" :class="{ hidden: !isMedium }" />
                <div>
                  <MaterialIcon class="text-3xl" aria-hidden="true">
                    face
                  </MaterialIcon>
                  <NotificationToast class="absolute -right-2 -top-2" />
                </div>
                <span class="sr-only">{{ t('header.userMenu') }}</span>
              </template>
            </DropdownMenu>
            <ButtonComponent
              v-else-if="isMedium"
              type="special"
              :text="t('header.login')"
              data-cy-id="LoginButton"
              @click="signIn(locale as supported_locale)"
            />
            <button
              v-else
              class="btn btn-circle glass btn-info bg-gnist-white"
              data-cy-id="LoginButton"
              @click="signIn(locale as supported_locale)"
            >
              <MaterialIcon class="px-5 text-2xl"> login </MaterialIcon>
            </button>
          </div>
          <DropdownMenu
            :items="filteredMenuItems"
            class="contents"
            :menu-items-class="[
              'absolute pb-4 flex-wrap gap-y-4 justify-center top-16 lg:top-24',
              isMedium
                ? 'pt-0 mt-0 inset-x-0 flex-row rounded-none basis-60'
                : 'pt-2 rounded-md',
            ]"
            data-cy-id="MainMenu"
          >
            <template #button="{ open: menuOpen }">
              <span
                class="mr-2 w-12 self-start text-lg text-gnist-black"
                :class="{ hideMarker: menuOpen, hidden: !isMedium }"
              >
                {{
                  menuOpen
                    ? t('header.closeMenuButton')
                    : t('header.menuButton')
                }}
              </span>
              <MaterialIcon
                class="text-3xl text-gnist-black"
                aria-hidden="true"
              >
                {{ menuOpen ? 'cancel' : 'menu' }}
              </MaterialIcon>
              <span class="sr-only">{{ t('header.mainMenu') }}</span>
            </template>
          </DropdownMenu>
        </nav>
        <div
          v-if="showMobileSearch"
          class="MobileSearchBarContainer absolute left-0 top-20 h-20 w-full px-4 py-4 md:mx-auto"
          :class="{ hidden: isMedium }"
        >
          <SearchBar
            class="text-p2 pr-4"
            @submit="
              (input) => {
                search(input);
                showMobileSearch = false;
              }
            "
          />
        </div>
      </div>
    </header>
    <div
      class="OverlayContainer fixed inset-x-0 inset-y-0 z-overlay hidden bg-gnist-black opacity-40"
    />
  </div>
</template>
<style>
/* prettier-ignore */
.HeaderContainer:not(.isMedium) .NavContainer:has(.hideMarker) > .SearchContainer,
.HeaderContainer:not(.isMedium) .NavContainer:has(.hideMarker) > .UserMenuContainer,
.HeaderContainer:not(.isMedium) .NavContainer:has(.hideMarker) + .MobileSearchBarContainer {
  @apply invisible;
}
.HeaderContainer:has(.hideMarker) .OverlayContainer {
  display: block;
}
</style>
